import {useTranslation} from "react-i18next";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import './modal.scss';
import {useOrderByIdQuery} from "../../../../../api/calc";
import moment from "moment";

type Props = {
    handleYes(): void,
    handleNo(): void,
    id: string
}

const Modal = ({handleYes, handleNo, id} : Props) => {

  const { t } = useTranslation()

  const { data: order } = useOrderByIdQuery(id)

  return (
    <div className='calculator__modal'>
      <Typography variant='body1' sx={{
        marginBottom: 3
      }}>
        {order &&
            <>
              {t('common__text__on')}&nbsp;
              {moment(order.updated_at).format('Do MMMM YYYY')} {t('common__text__in')}&nbsp;
              {moment(order.updated_at).format('HH:mm:ss')} {t('calc__message__modal__doYouWantToContinue')}
            </>
        }
      </Typography>
      <div className='calculator__modal-buttons'>
        <Button
          variant='outlined'
          onClick={handleNo}
          sx={{ marginRight: 3, width: 332}}
        >{t('calc__text__modal__createNewCalculation')}</Button>
        <Button
          variant='contained'
          onClick={handleYes}
          sx={{ width: 332}}
        >{t('calc__text__modal__continueCalculation')}</Button>
      </div>
    </div>
  )
}

export default Modal
