import React from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

import CodeServices from "./components/code-services";

import {COLORS} from "../../../../../../../config/constants";

import './company-card.scss'

type Props = {
    isActive?: boolean;
    company: BrokerCompany;
    handleChoose?(id: number): void;
}

const CompanyCard = ({ isActive, company, handleChoose } : Props ) => {

  const { t } = useTranslation()

  const name = company.broker.name;

  const handleSave = () => {
    if (handleChoose) {
      handleChoose(company.tariff_id);
    }
  }

  return <Card className={cx('company-card', {
    'company-card_active': isActive,
  })} sx={{ background: isActive ? COLORS.LIME : COLORS.WHITE, borderRadius: 0, boxShadow: 'none' }}>
    <CardContent sx={{
      padding: 0, '&:last-child': {
        paddingBottom: 0
      }
    }}>
      <div className='company-card__main-content'>
        <div className='company-card__left'>
          <span className='company-card__logo'>{name[0]}</span>
          <Typography variant='subtitle2'>
            {name}
          </Typography>
        </div>
        <div className={cx('company-card__right', {
          'company-card__right_active': isActive,
        })}>
          <Typography variant='h6' sx={{ lineHeight: 1.2 }}>
            {company.cost_total} {company.currency}
            <span className='company-card__subtitle'>{t('calc__text__customs__forTheDeclaration')}</span>
          </Typography>
          <Button
            variant='outlined'
            fullWidth
            onClick={handleSave}
            startIcon={<CheckIcon />}
            sx={{
              width: isActive ? 145 : 155,
              '&.Mui-disabled': {
                backgroundColor: COLORS.SLIGHTLY_GRAY,
                color: COLORS.LIGHT_TEXT_DISABLED_OPACITY,
                border: 'none'
              }
            }}
            disabled={isActive}
          >{isActive ? t('common__text__selected') : t('actions__text__choose')}</Button>
        </div>
      </div>
      <Divider/>
      <CodeServices isActive={isActive} services={company.services}/>
    </CardContent>
  </Card>
}

export default CompanyCard
