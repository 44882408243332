import { DatePicker } from '@mui/x-date-pickers-pro';
import {useFormContext} from "react-hook-form";
import {DELIVERY_FORM, DeliveryFormFields} from "./index";
import {useTranslation} from "react-i18next";
import moment, {Moment} from 'moment'
import 'moment/locale/ru';
import {useState} from "react";

type Props = {
  isBigInputs?: boolean
  defaultValue?: Moment
}


const DateInput  = ( {isBigInputs, defaultValue} : Props ) => {

  const { t } = useTranslation()

  const { register, getValues, setValue, trigger } = useFormContext<DeliveryFormFields>()

  const [ date, setDate ] = useState<Moment | null>(defaultValue || moment(getValues()[DELIVERY_FORM.PLANNED_SHIPMENT_DATE] || ''));
  const [ error, setError ] = useState(false);

  const handleValidateDatePicker = async (value: Moment | null) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    setDate(value);
    value && setValue(DELIVERY_FORM.PLANNED_SHIPMENT_DATE, formattedDate)
    setError(formattedDate === 'Invalid date' ? true: Boolean(moment().diff(moment(value), 'days') > 0))
    await trigger(DELIVERY_FORM.PLANNED_SHIPMENT_DATE);
  }

  return (
    <div id={DELIVERY_FORM.PLANNED_SHIPMENT_DATE}
    >
      <DatePicker
        label={t('calc__text__delivery__planShippingTime')}
        {...register(DELIVERY_FORM.PLANNED_SHIPMENT_DATE, { required: t('common__text__isRequired') })}
        value={date}
        disablePast
        sx={{ maxWidth: isBigInputs ? '360px' : 'auto', width: '100%', 'svg' : { width: '32px' } }}
        onChange={(value) => handleValidateDatePicker(value)}
        slotProps={{
          textField: {
            error,
            helperText: error ? t('errors__message__incorrectDate') : ''},
        }}
      />
    </div>

  )
}

export default DateInput
