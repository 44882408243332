import React from "react";
import Typography from "@mui/material/Typography";
import {COLORS} from "../../../../../../../config/constants";
import Arrow from './arrow.svg';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { Ship, Car, Plane, Train, Customs } from "../../../../../../../assets/svg";
import {useTranslation} from "react-i18next";
import {getPluralForm} from "../../../../../../../utils/validation";
import moment from "moment";
import cx from "classnames";
import {getFlag} from "../../helpers";

enum DELIVERY_TYPE {
  SEA = 'sea',
  AUTO = 'auto',
  AVIA = 'avia',
  RAILS = 'rails',
}

const getIcon = (type: DELIVERY_TYPE) => {
  switch (type) {
    case DELIVERY_TYPE.SEA:
      return <Ship className='point__deliverySign'/>
    case DELIVERY_TYPE.AUTO:
      return <Car className='point__deliverySign'/>
    case DELIVERY_TYPE.AVIA:
      return <Plane className='point__deliverySign'/>
    case DELIVERY_TYPE.RAILS:
      return <Train className='point__deliverySign'/>
    default:
      return null;
  }
}

const MAX_TEXT_LENGTH = 20;

type Props = {
  isActive?: boolean
  route: Route,
}

type Point = {
  date?: string
  caption: string
  code: string
  shipping_method: DELIVERY_TYPE
  min_days?: number
  max_days?: number
  countryCode?: string
  customs_point: boolean
}

type DeliveryTooltipProps = {
  text: string
  image: any
}

const DeliveryTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 10,
    lineHeight: '14px',
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8,
    borderRadius: 0,
  },
}));

const CustomsTooltip = ({text, image}: DeliveryTooltipProps) => {

  return (
    <DeliveryTooltip
      title={text}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [ 0, -4 ],
              },
            },
          ],
        },
      }}
    >
      {image}
    </DeliveryTooltip>
  )
}

const Route = ({route} : Props) => {

  const { t } = useTranslation()

  if (!route) {
    return null;
  }

  const DAYS_DECLENSIONS: PluralForms = [ t('declension__text__daysOne'), t('declension__text__daysSeveral'), t('declension__text__daysMany') ];

  const getPoint = (point: Point, index: number, freightsLength: number | undefined) => {

    if (!freightsLength) {
      return;
    }

    const shouldDuplicateArrow = index === 3 || index === 6;

    const hasOneStopMax = freightsLength === 2 || freightsLength === 3;

    const freightDuration = point.max_days ? `≈ ${point.min_days}-${point.max_days} ${getPluralForm(point.max_days, DAYS_DECLENSIONS)}` : '';

    const isFirstPoint = index === 0;
    const isLastPoint = index === freightsLength - 1;

    const getSign = (freightsLength: number, index: number) => {
      if (index === 0) {
        return 'A'
      }
      if (index === freightsLength - 1) {
        return 'B'
      }
      return <span className='point__dot'/>
    }

    if (shouldDuplicateArrow) {
      return <div className='point point-duplicate' key={`${index}-duplicate`}>
        <div className='point__plan point__plan_fixed'>
          <span className='point__img point__img_empty'/>
          {(index < freightsLength - 1) && (<>
            <span className='point__line'>
              <img src={Arrow} alt='' width='8px' className='point__line-arrow'/>
            </span>
            <CustomsTooltip text={freightDuration} image={getIcon(point.shipping_method)}/>
          </>)}
          {(index === freightsLength - 1) && (
            <>
              <span className='point__img'>
                {getSign(freightsLength, index, )}
              </span>
              {point.customs_point && <CustomsTooltip text={t('common__text__customs')} image={<Customs />}/>}
            </>
          )}
        </div>
      </div>
    }

    const truncateText = (text: string) => {
      if (text.length > MAX_TEXT_LENGTH) {
        return text.slice(0, MAX_TEXT_LENGTH) + '...';
      }
      return text;
    }

    return (
      <div className={cx('point', {
        'point_fullWidth': hasOneStopMax,
      })} key={index}>
        <div className='point__text'>
          {point.date && <Typography variant='body2' sx={{fontWeight: 500, color: COLORS.BLACK}}>
            {moment(point.date).format('DD.MM.YYYY')}
          </Typography>}
          {point.caption && <Typography
            variant='caption'
            sx={{
              color: COLORS.BLACK,
              display: 'flex',
              alignItems: 'center',
              justifyContent: isLastPoint ? 'end': 'start'
            }}>
            {isFirstPoint && getFlag({code: point.countryCode, height: '14px', marginRight: '4px'  })}
            {point.caption.length > MAX_TEXT_LENGTH ? <Tooltip title={point.caption}>
              <div>{truncateText(point.caption)}</div>
            </Tooltip> : point.caption}
            {isLastPoint && <span className='point__flag-container'>&nbsp;{getFlag({code: point.countryCode, height: '14px',  marginRight: '0px'  })}</span>}
          </Typography>}
          {<Typography variant='caption' color='rgba(0, 0, 0, 0.38)' className='point__location'>
            {point.code}
          </Typography>}
        </div>
        <div className={cx('point__plan', {
          'point__plan_fixed': !hasOneStopMax,
        })}>
          <span className='point__img'>
            {getSign(freightsLength, index)}
          </span>
          {point.customs_point &&
              <span className='point__customs-container'>
                <CustomsTooltip text={t('common__text__customs')} image={<Customs />}/>
              </span>
          }
          {(index < freightsLength - 1) && (<>
            <span className='point__line'>
              <img src={Arrow} alt='' width='8px' className='point__line-arrow'/>
            </span>
            <CustomsTooltip text={freightDuration} image={getIcon(point.shipping_method)}/>
          </>)}
        </div>
      </div>
    )
  }


  const getRoutes = (data: Freight[]) => {

    if (!data) {
      return undefined;
    }

    const startRoute = {
      date: route.planned_shipment_date,
      caption: route.location_from?.name,
      code: route.location_from?.code,
      shipping_method: data?.[0].shipping_method?.code,
      max_days: data[0]?.max_days,
      min_days: data[0]?.min_days,
      countryCode: route.location_from.country.code,
      customs_point: false,
    }

    const freightsList = data.map((freight: Freight, index: number, array) => (
      {
        date: index === data.length - 1 ? route.planned_delivery_date: undefined,
        caption: freight.location_to?.name,
        code: freight.location_to?.code,
        shipping_method: data[index + 1]?.shipping_method.code,
        max_days: data[index + 1]?.max_days,
        min_days: data[index + 1]?.min_days,
        countryCode: freight.city_to?.country.code,
        customs_point: array.some(
          (otherFreight) =>
            freight.location_to.code === otherFreight?.customs_point?.location.code
        ),
      }
    ));

    return [ startRoute, ...freightsList ];
  }

  return <div className='route-card'>
    {route.min_days && route.max_days && (
      <Typography variant='body2' sx={{ fontWeight: 500, color: COLORS.BLACK }} className='route-card__duration'>
        {`${route.min_days} - ${route.max_days} ${getPluralForm(route.max_days, DAYS_DECLENSIONS)}`}
      </Typography>
    )}
    <div className='route'>
      {getRoutes(route.freights)?.map((point, index) => getPoint(point, index, getRoutes(route?.freights)?.length ))}
    </div>
  </div>

}

export default Route
