import React from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

import Completed from "../../../../../../../../common/completed-new";

import './code-services.scss'

type Props = {
    isActive?: boolean
    services: BrokerService[]
}

const CodeServices = ({isActive, services} : Props) => {

  const { t } = useTranslation()

  const getCompletedInfo = () => {
    const companyServices: { label: string; value: string; }[] = [];
    services.map(service => {
      companyServices.push({
        label: service.name,
        value: ''
      });
    })
    return companyServices;
  }

  return <Accordion
    sx={{
      marginBottom: 0,
      background: 'inherit',
      boxShadow: 'none',
      '&.Mui-expanded': {
        marginBottom: 0
      }
    }}
    className='code-services'
    defaultExpanded={!isActive}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      sx={{
        margin: '0',
        minHeight: '60px',
        padding: '0px 28px 0 24px',
        '&.Mui-expanded': {
          minHeight: '60px',
          borderBottom: 'none'
        }
      }}
    >
      <div className='code-services__title'>
        <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
          {t('calc__text__delivery__tariffTermsAndAdditionalServices')}
        </Typography>
      </div>
    </AccordionSummary>
    <AccordionDetails sx={{padding: '0 24px 24px 24px'}}>
      <Completed
        info={getCompletedInfo()}
        isFullRow
        hasNoValues
      />
    </AccordionDetails>
  </Accordion>
}

export default CodeServices
